<template>
  <v-container fluid class="px-0 py-0">
    <h1 class="custom-header-blue-text px-0 py-3">Settings</h1>
    <v-tabs
      vertical
      v-model="settingTab"
      background-color="transparent"
      color="blue darken-4 "
      class="tabs_btn test"
    >
      <v-tab
        v-for="(tab, index) in SettingTabs"
        :key="index"
        :href="'#' + tab.key"
        class="d-flex test justify-start"
      >
        {{ tab.title }}
      </v-tab>
      <v-tabs-items v-model="settingTab" class="pb-2 px-2 py-0 justify-start">
        <template v-for="(tab, index) in SettingTabs">
          <v-tab-item class="min-height-550px" :value="tab.key" :key="index">
            <component :is="tab.template" />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import ProfileTemplate from "@/view/module/setting/ProfileTemplate";
import CategoryTemplate from "@/view/module/setting/CategoryTemplate";
import VoucherTemplate from "@/view/module/setting/VoucherTemplate";
import PromotionTemplate from "@/view/module/setting/PromotionTemplate";
import ActivityTemplate from "@/view/module/setting/ActivityTemplate";
import DepartmentTemplate from "@/view/module/setting/DepartmentTemplate";
import UnitsTemplate from "@/view/module/setting/UnitsTemplate";
import SupplierCategoryTemplate from "@/view/module/setting/SupplierCategoryTemplate";
import BrandsTemplate from "@/view/module/setting/BrandsTemplate";
import SourcesTemplate from "@/view/module/setting/SourcesTemplate";
import EventsTemplate from "@/view/module/setting/EventsTemplate";



export default {
  data() {
    return {
      SettingTabs: [
        {
          key: "company",
          template: ProfileTemplate,
          title: "Company",
        },
        {
          key: "categories",
          template: CategoryTemplate,
          title: "Department Categories",
        },
        {
          key: "voucher",
          template: VoucherTemplate,
          title: "Vouchers",
        },
        {
          key: "promotion",
          template: PromotionTemplate,
          title: "Promotions",
        },
        {
          key: "activity-log",
          template: ActivityTemplate,
          title: "Activity Log",
        },
        {
          key: "department",
          template: DepartmentTemplate,
          title: "Budget Category",
        },
        {
          key: "units",
          template: UnitsTemplate,
          title: "Units",
        },
        {
          key: "events",
          template: EventsTemplate,
          title: "Events",
        },
        {
          key: "brands",
          template: BrandsTemplate,
          title: "Brands",
        },
        {
          key: "supplierCategory",
          template: SupplierCategoryTemplate,
          title: "Category",
        },
        {
          key: "sources",
          template: SourcesTemplate,
          title: "Sources",
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.tab) {
      this.settingTab = this.$route.query.tab;
    } else {
      this.$router.replace({ query: { tab: "company", t: new Date().getTime() } });
    }
  },
  computed: {
    settingTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        query.t = new Date().getTime();
        if (val != this.settingTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "company";
      },
    },
  },
};
</script>
<style scoped>
.tabs_btn a.v-tab.v-tab--active {
  background-color: rgb(220, 220, 241);
}
.tabs_btn {
  padding-top: 20px;
}
.test {
  padding-top: 0px;
}
</style>

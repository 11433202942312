<template>
  <Dialog dense :dialog="dialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-layout>
        <v-flex>History | {{ hTitle }}</v-flex>
        <v-flex class="text-right">
          <v-btn :disabled="pageLoading" depressed tile v-on:click="closeDialog()"> Close </v-btn>
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <v-container fluid class="pt-0">
        <v-row>
          <v-col md="8" class="py-0">
            <TextInput
              clearable
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="voucher-history"
              placeholder="Search by Code, Customer Name, Customer Phone No., Customer Email, POS Bill #"
              v-model="search"
              class="mb-2"
              v-on:click:clear="findHistory()"
              v-on:keydown:esc="findHistory()"
              v-on:keydown:enter="findHistory()"
              v-on:keydown:tab="findHistory()"
            ></TextInput>
          </v-col>
          <v-col md="4" class="py-0">
            <v-btn-toggle
              v-model="historyTab"
              dense
              depressed
              tile
              class="my-2"
              color="blue darken-4"
            >
              <v-btn value="all"> All </v-btn>

              <v-btn value="available"> Available </v-btn>

              <v-btn value="collected"> Collected </v-btn>

              <v-btn value="redeemed"> Redeemed </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-simple-table class="bt-table" fixed height="500">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Code</th>
                <th>Status</th>
                <th>Customer</th>
                <th>Collected Order</th>
                <th>Redeemed Order</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <template v-if="history.length">
                <tr v-for="(row, index) in history" :key="index">
                  <td>
                    <Chip :text="row.vcode"></Chip>
                  </td>
                  <td>
                    <p class="m-0">
                      <span class="text--secondary">Collected : </span>
                      <template v-if="row.collected">Yes</template>
                      <template v-else>No</template>
                    </p>
                    <p class="m-0">
                      <span class="text--secondary">Redeemed : </span>
                      <template v-if="row.redeemed">Yes</template>
                      <template v-else>No</template>
                    </p>
                  </td>
                  <td>
                    <template v-if="row.collected">
                      <p class="m-0">
                        <span class="text--secondary">Name : </span
                        ><ValueTemplate
                          v-model="row.customer_full_name"
                          title="Name"
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Phone No. : </span
                        ><ValueTemplate
                          v-model="row.customer_phone_number"
                          title="Phone No."
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Email : </span
                        ><ValueTemplate
                          class="text-lowercase"
                          v-model="row.customer_email"
                          title="Email"
                        ></ValueTemplate>
                      </p>
                    </template>
                    <template v-else>
                      <v-chip
                        v-if="row.expired"
                        class="ma-2"
                        color="red lighten-1"
                        tile
                        text-color="white"
                      >
                        Expired
                      </v-chip>
                      <v-chip v-else class="ma-2" color="green darken-1" tile text-color="white">
                        Available
                      </v-chip>
                    </template>
                  </td>
                  <td>
                    <template v-if="row.collected">
                      <p class="m-0">
                        <span class="text--secondary">POS Bill # : </span
                        ><ValueTemplate
                          v-model="row.corder_bill_no"
                          title="POS Bill #"
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Date Time : </span
                        ><ValueTemplate
                          v-model="row.order_cdatetime"
                          datetime
                          title="Date Time"
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Amount : </span
                        ><ValueTemplate
                          v-model="row.corder_amount"
                          currency
                          title="Amount"
                        ></ValueTemplate>
                      </p>
                    </template>
                    <template v-else>
                      <v-chip
                        v-if="row.expired"
                        class="ma-2"
                        color="red lighten-1"
                        tile
                        text-color="white"
                      >
                        Expired
                      </v-chip>
                      <v-chip v-else class="ma-2" color="green darken-1" tile text-color="white">
                        Available
                      </v-chip>
                    </template>
                  </td>
                  <td>
                    <template v-if="row.redeemed">
                      <p class="m-0">
                        <span class="text--secondary">POS Bill # : </span
                        ><ValueTemplate
                          v-model="row.rorder_bill_no"
                          title="POS Bill #"
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Date Time : </span
                        ><ValueTemplate
                          v-model="row.order_rdatetime"
                          datetime
                          title="Date Time"
                        ></ValueTemplate>
                      </p>
                      <p class="m-0">
                        <span class="text--secondary">Amount : </span
                        ><ValueTemplate
                          v-model="row.rorder_amount"
                          currency
                          title="Amount"
                        ></ValueTemplate>
                      </p>
                    </template>
                    <template v-else>
                      <v-chip
                        v-if="row.expired"
                        class="ma-2"
                        color="red lighten-1"
                        tile
                        text-color="white"
                      >
                        Expired
                      </v-chip>
                      <v-chip v-else class="ma-2" color="green darken-1" tile text-color="white">
                        Available
                      </v-chip>
                    </template>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="5">
                  <p class="m-0 text-center">
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mr-4"
                    />
                    Uhh... There are no history at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 5" :key="idr">
                <td v-for="idk in 5" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-container>
    </template>
  </Dialog>
</template>
<script>
import { GET_VOUCHER_HISTORY } from "@/core/lib/common.lib";
import { toSafeInteger } from "lodash";
import TextInput from "@/view/components/TextInput";
import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    hTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      history: [],
      search: null,
      historyTab: "all",
      pageLoading: false,
    };
  },
  watch: {
    historyTab() {
      this.findHistory();
    },
    dialog(param) {
      if (param) {
        this.findHistory();
      }
    },
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
  methods: {
    closeDialog() {
      this.history = [];
      this.search = null;
      this.historyTab = "all";
      this.pageLoading = false;
      this.$emit("close", true);
    },
    async findHistory() {
      if (this.id) {
        try {
          this.pageLoading = true;
          this.history = await GET_VOUCHER_HISTORY(this.id, {
            search: this.search,
            tab: this.historyTab,
          });
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
  },
  mounted() {
    this.findHistory();
  },
  components: {
    Chip,
    Dialog,
    TextInput,
    ValueTemplate,
  },
};
</script>

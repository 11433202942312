<template>
  <v-card flat tile class="border-light-grey">
    <v-card-title class="headline grey lighten-4">
      <h3 class="custom-sub-header-blue-text">Activity Log</h3>
    </v-card-title>
    <v-progress-linear
      indeterminate
      height="6"
      v-if="pageLoading"
      class="position-absolute"
      color="blue darken-4"
    ></v-progress-linear>
    <v-card-text class="p-6">
      <v-row>
        <v-col md="6" class="py-0">
          <TextInput
            clearable
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="activity-search"
            placeholder="Search Activity"
            v-model="search"
            v-on:click:clear="getActivities()"
            v-on:keydown:esc="getActivities()"
            v-on:keydown:enter="getActivities()"
            v-on:keydown:tab="getActivities()"
          ></TextInput>
        </v-col>
      </v-row>
      <v-simple-table class="bt-table mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Activity</th>
              <th width="200">Created At</th>
            </tr>
          </thead>
          <tbody v-if="!pageLoading">
            <template v-if="activities.length">
              <tr v-for="(row, index) in activities" :key="index">
                <td>{{ row.description }}</td>
                <td width="200">{{ formatDateTime(row.added_at) }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">
                  <p class="m-0 text-center">
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mr-4"
                    />
                    Uhh... There are no activity at the moment.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot v-else>
            <tr v-for="idr in 5" :key="idr">
              <td v-for="idk in 2" :key="idk">
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
      <v-layout v-if="activities.length" class="light-border-top mt-4">
        <v-flex md6>
          <label class="btx-label p-4"
            >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
          >
        </v-flex>
        <v-flex md6>
          <v-pagination
            color="blue darken-4"
            v-model="currentPage"
            :length="pageLimit"
            :total-visible="7"
          ></v-pagination>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ERROR } from "@/core/services/store/common.module";
import { GET_ACTIVITY } from "@/core/lib/common.lib";

export default {
  data() {
    return {
      search: null,
      pageLoading: false,
      activities: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
    };
  },
  watch: {
    currentPage() {
      this.getActivities();
    },
  },
  methods: {
    async getActivities() {
      try {
        const form = {
          current_page: this.currentPage,
          search: this.search,
        };
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ACTIVITY(form);
        this.activities = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.getActivities();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Setting", disabled: true },
      { text: "Activities", disabled: true },
    ]);
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  components: {
    TextInput,
  },
};
</script>

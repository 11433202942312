<template>
  <v-card>
    <v-card-title
      class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
    >
      <v-layout>
        <v-flex class="my-auto"> Sub Categories of {{ categoryName }} </v-flex>
        <v-flex class="text-right">
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="dialog = true"
          >
            Update
          </v-btn>
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="ml-2"
            depressed
            tile
            v-on:click="$emit('close', true)"
          >
            Close
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-progress-linear
      indeterminate
      height="6"
      v-if="pageLoading"
      class="position-absolute"
      color="blue darken-4"
    ></v-progress-linear>
    <v-card-text class="p-6">
      <v-row>
        <v-col md="6" class="py-0">
          <TextInput
            clearable
            hide-details
            :disabled="pageLoading"
            :loading="pageLoading"
            id="category-search"
            placeholder="Search Sub Category"
            v-model="search"
            v-on:click:clear="getSubCategories()"
            v-on:keydown:esc="getSubCategories()"
            v-on:keydown:enter="getSubCategories()"
            v-on:keydown:tab="getSubCategories()"
          ></TextInput>
        </v-col>
      </v-row>
      <v-simple-table class="bt-table mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th width="50">#</th>
              <th>Sub Category</th>
              <th width="200">Created At</th>
              <th width="200">Last Modified At</th>
            </tr>
          </thead>
          <tbody v-if="subCategories.length">
            <tr v-for="(cat, ind) in subCategories" :key="ind">
              <td width="50">{{ ind + 1 }}</td>
              <td>{{ cat.name }}</td>
              <td width="200">{{ formatDateTime(cat.added_at) }}</td>
              <td width="200">
                <template v-if="cat.updated_at">{{ formatDateTime(cat.updated_at) }}</template
                ><em class="text--secondary" v-else>N/A</em>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no sub category at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <Dialog :dialog="dialog" card-text-class="py-0">
      <template v-slot:title>
        <v-layout>
          <v-flex>Sub Categories</v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text"
              depressed
              color="blue darken-4"
              tile
              v-on:click="addSubCategory()"
            >
              <v-icon left>mdi-plus</v-icon>
              Add...
            </v-btn>
          </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <v-container fluid :class="{ 'pt-0': subCategories.length, 'py-2': !subCategories.length }">
          <template v-if="subCategories.length">
            <template v-for="(row, ind) in subCategories">
              <TextInput
                :key="ind"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                :id="`category-${ind}`"
                placeholder="Sub Category"
                v-model="row.name"
                append-outer-icon="mdi-delete"
                class="has-delete-outer"
                v-on:click:append-outer="removeSubCategory(ind)"
              ></TextInput>
            </template>
          </template>
          <template v-else>
            <p class="m-0 text-center nothing-found-tag">
              <img
                width="30"
                :src="$assetURL('media/error/empty.png')"
                class="row-not-found-image mr-4"
              />
              Uhh... There are no sub category at the moment.
            </p>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="updateSubCategories()"
        >
          Save
        </v-btn>
        <v-btn :disabled="pageLoading" depressed tile v-on:click="getSubCategories()">
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-card>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_SUB_CATEGORY, UPDATE_SUB_CATEGORY } from "@/core/lib/common.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  data() {
    return {
      search: null,
      dialog: false,
      pageLoading: false,
      subCategories: [],
    };
  },
  props: {
    category: {
      type: Number,
      default: 0,
    },
    categoryName: {
      type: String,
      default: null,
    },
  },
  watch: {
    category() {
      this.getSubCategories();
    },
  },
  methods: {
    addSubCategory() {
      this.subCategories.push({
        name: null,
      });
    },
    removeSubCategory(index) {
      this.subCategories.splice(index, 1);
      if (this.subCategories.length <= 0) {
        this.addSubCategory();
      }
    },
    async getSubCategories() {
      const _this = this;
      try {
        if (!_this.category) {
          return false;
        }
        _this.pageLoading = true;
        _this.subCategories = await GET_SUB_CATEGORY({
          search: _this.search,
          parent: _this.category,
        });
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
        _this.dialog = false;
      }
    },
    async updateSubCategories() {
      const _this = this;
      try {
        if (!_this.category) {
          return false;
        }
        for (let i = 0; i < this.subCategories.length; i++) {
          // if (this.categories && this.categories[i] && this.categories[i].file) {
          // 	formData.append(`file[${i}][file]`, this.categories[i].file);
          // }
          if (this.subCategories && this.subCategories[i] && !this.subCategories[i].name) {
            _this.$store.commit(SET_ERROR, [{ model: true, message: "This field is required" }]);
            return false;
          }
        }
        _this.pageLoading = true;
        await UPDATE_SUB_CATEGORY({ options: _this.subCategories, parent: _this.category });
        _this.getSubCategories();
        _this.$emit("close", true);
        _this.dialog = false;
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Sub Categories Updated Successfully" },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.getSubCategories();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Setting", disabled: true },
      { text: "Sub Categories", disabled: true },
    ]);
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
  components: {
    Dialog,
    TextInput,
  },
};
</script>

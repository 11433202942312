<template>
    <v-card flat tile class="border-light-grey">
      <v-card-title class="headline grey lighten-4">
        <v-layout>
          <v-flex class="my-auto">
            <h3 class="custom-sub-header-blue-text m-0">Events</h3>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="dialog = true"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-progress-linear
        indeterminate
        height="6"
        v-if="pageLoading"
        class="position-absolute"
        color="blue darken-4"
      ></v-progress-linear>
      <v-card-text class="p-6">
        <v-row>
          <v-col md="6" class="py-0">
            <TextInput
              clearable
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="category-search"
              placeholder="Search event"
              v-model="search"
              v-on:click:clear="getUnits()"
              v-on:keydown:esc="getUnits()"
              v-on:keydown:enter="getUnits()"
              v-on:keydown:tab="getUnits()"
            ></TextInput>
          </v-col>
        </v-row>
        <v-simple-table class="bt-table mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="50">#</th>
                <th>Events</th>
                <th width="200">Created At</th>
                <th width="200">Last Modified At</th>
              </tr>
            </thead>
            <tbody v-if="categories.length">
              <tr class="cursor-pointer" v-for="(cat, ind) in categories" :key="ind">
                <td width="50">{{ ind + 1 }}</td>
                <td>{{ cat.name }}</td>
                <td width="200">{{ formatDateTime(cat.added_at) }}</td>
                <td width="200">
                  <template v-if="cat.updated_at">{{ formatDateTime(cat.updated_at) }}</template
                  ><em class="text--secondary" v-else>N/A</em>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">
                  <p class="m-0 text-center">
                    <img
                      width="30"
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image mr-4"
                    />
                    Uhh... There are no events at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <Dialog :dialog="dialog">
        <template v-slot:title>
          <v-layout>
            <v-flex>Events</v-flex>
            <v-flex class="text-right">
              <v-btn
                :disabled="pageLoading"
                :loading="pageLoading"
                class="white--text"
                depressed
                color="blue darken-4"
                tile
                v-on:click="addCategory()"
              >
                <v-icon left>mdi-plus</v-icon>
                Add...
              </v-btn>
            </v-flex>
          </v-layout>
        </template>
        <template v-slot:body>
          <v-container fluid class="pt-0">
            <template v-for="(row, ind) in categories">
              <TextInput
                :key="ind"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                :id="`category-${ind}`"
                placeholder="event"
                v-model="row.name"
                append-outer-icon="mdi-delete"
                class="has-delete-outer"
                v-on:click:append-outer="removeCategory(ind)"
              ></TextInput>
            </template>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="pageLoading"
            :loading="pageLoading"
            class="white--text mr-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="updateCategories()"
          >
            Save
          </v-btn>
          <v-btn :disabled="pageLoading" depressed tile v-on:click="getUnits()"> Cancel </v-btn>
        </template>
      </Dialog>
      <Dialog dense :dialog="subCategoryDialog" :dialog-width="dialogWidth">
        <template v-slot:body>
          <SubCategoryTemplate
            v-if="subCategoryDialog"
            :category="category"
            :category-name="categoryName"
            v-on:close="subCategoryDialog = false"
          ></SubCategoryTemplate>
        </template>
      </Dialog>
    </v-card>
  </template>
  
  <script>
  import { toSafeInteger } from "lodash";
  import TextInput from "@/view/components/TextInput";
  import Dialog from "@/view/components/Dialog";
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import { GET_EVENTS, UPDATE_EVENTS } from "@/core/lib/common.lib";
  import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
  import SubCategoryTemplate from "@/view/module/setting/SubCategoryTemplate";
  
  export default {
    data() {
      return {
        search: null,
        dialog: false,
        subCategoryDialog: false,
        pageLoading: false,
        category: 0,
        categoryName: null,
        categories: [],
      };
    },
    methods: {
      updateSubCategories({ id, name }) {
        this.category = id;
        this.categoryName = name;
        this.subCategoryDialog = true;
      },
      addCategory() {
        this.categories.push({
          name: null,
        });
      },
      removeCategory(index) {
        this.categories.splice(index, 1);
        if (this.categories.length <= 0) {
          this.addCategory();
        }
      },
      async getUnits() {
        const _this = this;
        try {
          _this.pageLoading = true;
          _this.categories = await GET_EVENTS({ search: this.search });
          if (_this.categories.length <= 0) {
            _this.addCategory();
          }
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
          _this.dialog = false;
        }
      },
      async updateCategories() {
        const _this = this;
        try {
          _this.pageLoading = true;
          for (let i = 0; i < this.categories.length; i++) {
            // if (this.categories && this.categories[i] && this.categories[i].file) {
            // 	formData.append(`file[${i}][file]`, this.categories[i].file);
            // }
            if (this.categories && this.categories[i] && !this.categories[i].name) {
              _this.$store.commit(SET_ERROR, [{ model: true, message: "This field is required" }]);
              return false;
            }
          }
          await UPDATE_EVENTS({ options: _this.categories });
          _this.getUnits();
          _this.dialog = false;
          _this.$store.commit(SET_MESSAGE, [{ model: true, message: "Events Updated Successfully" }]);
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
        }
      },
    },
    mounted() {
      this.getUnits();
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Setting", disabled: true },
        { text: "Categories", disabled: true },
      ]);
    },
    beforeDestroy() {
      this.$store.dispatch(SET_BREADCRUMB, []);
    },
    components: {
      Dialog,
      TextInput,
      SubCategoryTemplate,
    },
    computed: {
      dialogWidth() {
        return toSafeInteger((document.body.clientWidth / 100) * 70);
      },
    },
  };
  </script>
  